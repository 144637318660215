import React, { useState } from "react"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import { Formik, Form, Field, ErrorMessage } from "formik"

import styles from "./contact.module.css"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const validate = values => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  const errors = {}
  if (!values.name) {
    errors.name = "Name is required"
  }
  if (!values.email || !emailRegex.test(values.email)) {
    errors.email = "A valid email is required"
  }
  if (!values.message) {
    errors.message = "A message is required"
  }
  return errors
}

const Contact = ({ data, location }) => {
  const siteTitle = "Contact"
  const siteDesc =
    "Fill the following form to send us a message. We will reply as soon as possible."
  const [success, setSuccess] = useState(false)
  const [globalError, setGlobalError] = useState(false)

  const handleSubmit = (values, actions) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values }),
    })
      .then(() => {
        setSuccess(true)
        setGlobalError(false)
        actions.resetForm()
      })
      .catch(() => {
        setSuccess(false)
        setGlobalError(true)
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={siteTitle} description={siteDesc} />

      <main className={styles.main}>
        <Formik
          initialValues={{
            name: "",
            email: "",
            message: "",
          }}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {() => (
            <Form name="contact" data-netlify={true}>
              {success && (
                <div className={styles.success}>
                  Your request has been successfully submitted.
                </div>
              )}
              {globalError && (
                <div className={styles.globalError}>
                  An error occured submitting your request. Please try again.
                </div>
              )}
              <div className={styles.formGroup}>
                <div className={styles.inputRow}>
                  <label htmlFor="name">Name</label>
                  <div className={styles.error}>
                    <Field name="name" />
                    <ErrorMessage name="name" />
                  </div>
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.inputRow}>
                  <label htmlFor="email">Email</label>
                  <div className={styles.error}>
                    <Field name="email" />
                    <ErrorMessage name="email" />
                  </div>
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.inputRow}>
                  <label htmlFor="message">Message</label>
                  <div className={styles.error}>
                    <Field name="message" component="textarea" />
                    <ErrorMessage name="message" />
                  </div>
                </div>
              </div>

              <div className={styles.formGroup}>
                <div className={styles.button}>
                  <button type="submit" className="btn btn-primary">
                    Send message
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </main>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
